export default [
    {
      key: 'name',
      label: 'field.categoryName',
      rules: 'required|max:100',
      type: 'text',
      cols : 12
    },
    {
      key: 'isEnable',
      label: 'status.active',
      rules: '',
      type: 'checkbox',
      cols: 'auto',
    },
  ]
  